/* ===================================
    3. Home Section CSS
====================================== */

#home {
  height: 100vh;
  padding: 85px 0;
  margin: 0;
}

#home .container-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

#home .main-photo {
  max-height: calc(100vh - 170px);
  width: 536px;
  object-fit: cover;
}

#home .entry-title {
  position: absolute;
  color: #0810F5;
  font-weight: 800;
  font-size: 140px;
  line-height: 143px;
  letter-spacing: -4px;
  z-index: 1;
  left: 0;
}

#home .entry-title span {
  text-decoration: underline;
}

#home .main-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

#home .main-btn img {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-name: scroll;
  display: block;
}

@keyframes scroll {
  0% {
    opacity: 1;
    transform: translateY(-25px);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

/* == */

@media (max-width: 1366px) {
  #home {
    padding: 60px 0;
  }

  #home .main-photo {
    width: 470px;
    max-height: calc(100vh - 120px);
  }

  #home .main-btn img {
    width: 40px;
  }

  #home .entry-title {
    font-size: 120px;
    line-height: 123px;
  }

  .block-right {
    margin-right: -60px;
  }

  img.block-right {
    max-width: calc(100% + 60px);
  }
}

@media (min-width: 1200px) and (max-height: 660px) {
  #home .main-photo {
    width: 440px;
  }

  #home .entry-title {
    font-size: 110px;
    line-height: 113px;
  }
}

@media (max-width: 991px) {
  #home {
    /* display: flex; */
    align-items: center;
  }

  #home .entry-title {
    font-size: 110px;
    line-height: 113px;
  }

  .serv-block-wrap .serv-block-item {
    margin-bottom: 65px;
  }

  .block-right {
    margin-right: 0;
  }

  img.block-right {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  #home .main-photo {
    width: auto;
    max-height: 500px;
    max-width: 65%;
  }

  #home .entry-title {
    font-size: 70px;
    line-height: 73px;
    max-width: 340px;
    letter-spacing: -2px;
  }

  #home {
    padding: 55px 0 0;
    height: auto;
  }

  #home .main-btn img {
    display: none;
  }
}

@media (max-width: 575px) {
  #home {
    overflow-x: hidden; /* Ensure no horizontal scrolling within #home */
  }

  #home .main-photo {
    max-width: 100%; /* Ensure the image does not exceed the container width */
    width: 85%; /* Scale the image to 85% of its container */
  }

  #home .entry-title {
    font-size: 56px;
    line-height: 62px;
    padding: 50px 0;
  }
}
