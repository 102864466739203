/*------------------------------------------------------------------

version 1.1

[Table of contents]
1. Global CSS
2. Header / Menu CSS
3. Home Section CSS 
4. Service Section CSS
5. About Section CSS
6. Portfolio Section CSS
    6.1 Pretty Photo CSS
7. Blog Section CSS
8. Skills Section CSS
9. Contact Section CSS
10. Single Blog / Post Page CSS
11. Responsive CSS

-------------------------------------------------------------------*/

/* ===================================
    1. Global CSS
====================================== */

/* ===================================
    5. About Section CSS
====================================== */

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap between items as needed */
  justify-content: flex-start; /* Align items to the left */
}

.flex-container a {
  flex: 1 1 auto; /* Adjust the basis as needed */
  display: flex;
  align-items: center;
}

.about-img {
  max-width: 100%;
  display: block;
}

.about-info {
  background-color: #0810F5;
  color: #fff;
  width: 330px;
  padding: 50px 30px 50px 70px;
  float: right;
  margin: 0 0 40px 65px;
}

.about-info li + li {
  margin-top: 44px;
}

.about-info h6 {
  font-size: 12px;
  letter-spacing: 5px;
  font-weight: 400;
}

.about-info a {
  color: #fff;
  display: inline-block;
  font-weight: 400;
}

.about-info a:hover {
  padding-left: 8px;
}

.about-text {
  padding-top: 53px;
}

.about-text p {
  max-width: 670px;
}

.signature {
  margin-top: 10px;
}

/* ===================================
    11. Responsive CSS
====================================== */

@media (max-width: 991px) {
  .about-info {
    margin: 0 0 40px 40px;
  }
}

@media (max-width: 767px) {
  .about-text {
    padding-top: 45px;
  }

  .about-info {
    width: 280px;
    padding: 50px 30px 50px 40px;
  }
}

@media (max-width: 575px) {
  .about-info {
    padding: 30px;
    margin: 0 0 40px 0px;
    float: none;
    width: 100%;
  }

  .about-info li + li {
    margin-top: 20px;
  }

  .about-text {
    padding-top: 0;
  }

  .signature {
    max-width: 100%;
  }
}
