/* ===================================
    6. Portfolio Section CSS
====================================== */

#portfolio-wrapper {
  padding-top: 30px;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

.grid {
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
}

.grid-item {
  position: relative;
  float: left;
  font-size: 0;
  line-height: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow: hidden;
  width: 50%;
  transition: opacity 0.3s;
}

.grid-item img {
  display: block;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
}

.grid-sizer,
.grid-item {
  width: 50%;
}

.grid-item.p-one {
  width: 100%;
}

.grid-item.p-half {
  width: 50%;
}

.portfolio-text-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  font-size: 20px;
  background-color: #000000;
  padding: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.grid-item a.item-link:hover .portfolio-text-holder,
.grid-item.portfolio-content-loading a.item-link .portfolio-text-holder {
  transform: translateX(0);
}

#portfolio-grid {
  animation: showing-portfolio-grid-animation 1.5s ease forwards;
}

@keyframes showing-portfolio-grid-animation {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid-item.portfolio-content-loading {
  opacity: 1 !important;
}

.portfolio-text-wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.portfolio-text {
  font-size: 28px;
  line-height: 100%;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.portfolio-cat {
  font-size: 15px;
  color: #fff;
  line-height: 100%;
  font-weight: 400;
  margin-bottom: 0;
}

/* .portfolio-load-content-holder {
  opacity: 0;
  transform: translateY(70px);
  transition: all 0.5s ease;
} */

/* .portfolio-load-content-holder.show {
  transform: translateY(0);
  opacity: 1;
} */

/* ===================================
    6.1 Pretty Photo CSS
====================================== */

#popup-root {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  pointer-events: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  padding: 10px;
  border: none;
  animation: show-popup 0.75s ease forwards;
}
@media (max-width: 730px) {
  .popup-content {
    width: calc(100% - 40px) !important;
  }
}
@keyframes show-popup {
  from {
    opacity: 0.4;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.poped-up-item {
  display: block;
  margin-bottom: 0 !important;
}

.popup-image-box {
  max-width: 700px;
  display: block;
}
.popup-image-box img {
  width: 100%;
}

.close-popup-btn {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: inline-block;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
}
.close-popup-btn img {
  display: block;
  width: 100%;
}

.icon-margin {
  margin-right: 10px;
}
