/* ===================================
    8. Skills Section CSS
====================================== */

.schedule-iframe {
  width: 90%;
  position: relative;
  display: block;
  overflow: hidden;
  margin-right: 10px;
  height: 300px;
}

.skills-history {
  background-color: #0810F5;
  color: #fff;
  margin-top: 41px;
  padding: 63px 60px 62px 94px;
}

.skills-history li {
  display: flex;
  align-items: center;
  position: relative;
}

.skills-history li:before {
  content: '';
  position: absolute;
  left: 28%;
  top: 0;
  background-color: #fff;
  width: 3px;
  height: 100%;
}

.skills-history li + li {
  padding-top: 37px;
}

.skills-history li .date {
  flex: 0 0 28%;
  max-width: 28%;
  font-weight: 200;
  font-size: 60px;
  line-height: 30px;
  transition: 0.35s ease-out;
}

.skills-history li:hover .date {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
  color: #000;
}

.skills-history li p {
  flex: 0 0 72%;
  max-width: 72%;
  padding: 0 50px 0 80px;
  cursor: default;
  transition: 0.35s ease-out;
  margin-bottom: 0;
}

.skills-history li:hover p {
  -webkit-transform: translateX(-15px);
  transform: translateX(-15px);
}

.skills-progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 40px;
  padding-top: 69px;
}

.skills-progress li {
  display: flex;
  align-items: center;
  width: calc(50% - 35px);
  margin-bottom: 22px;
}

.skills-progress .name {
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 3.5px;
  width: 37%;
  padding-right: 30px;
}

.skills-progress .skill {
  width: 100%;
  height: 10px;
  background-color: #ffd2d3;
  width: 63%;
}

.skills-progress .skill-fill {
  width: 10%;
  height: 10px;
  background-color: #0810F5;
  transition: width 0.7s;
}

/* REsp */

@media (max-width: 767px) {
  .skills-history {
    padding: 62px 30px 62px 30px;
  }

  .skills-history li .date {
    font-size: 46px;
  }

  .skills-history li p {
    padding: 0 20px 0 50px;
  }

  .skills-progress {
    padding-right: 0;
  }

  .skills-progress li {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .skills-history li {
    flex-wrap: wrap;
  }

  .skills-history li + li {
    padding-top: 60px;
  }

  .skills-history li:before {
    display: none;
  }

  .skills-history li p,
  .skills-history li .date {
    flex: 0 0 100%;
    max-width: 100%;
    transform: none !important;
  }

  .skills-history li p {
    padding: 0;
  }

  .skills-history li .date {
    margin-bottom: 20px;
  }

  .skills-progress li {
    flex-wrap: wrap;
  }

  .skills-progress li .name {
    width: 100%;
    margin-bottom: 5px;
  }

  .skills-progress li .skill {
    width: 100%;
  }
}
