.single-blog .entry-title {
  font-size: 32px;
  line-height: 35px;
  margin-bottom: 32px;
}

.single-blog .meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.single-blog .meta li {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 3px;
}

.single-blog .meta li + li {
  margin-left: 25px;
}

.single-blog .meta li a {
  color: #0810F5;
}

.single-blog .meta li a:hover {
  color: #000000;
}

.single-blog .thumbnail-img {
  position: relative;
  margin-bottom: 40px;
}

.single-blog .thumbnail-img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(244, 70, 71, 0.04);
}

.single-blog .thumbnail-img img {
  display: block;
  width: 100%;
}

.single-blog .content-670 blockquote {
  float: right;
  background: #0810F5;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  padding: 40px 55px 50px;
  width: 330px;
  margin: 5px 0 30px 18px;
}

.single-blog .content-670 blockquote p {
  margin: 0;
}

.single-blog .content-670 blockquote img {
  margin-bottom: 27px;
}

.single-blog .soc-list {
  margin-top: 60px;
}

.soc-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.soc-list li {
  margin-right: 25px;
}

.soc-list li:last-child {
  margin-right: 0;
}

.soc-list li a {
  color: #000;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 5px;
}

.soc-list li a:hover {
  color: #0810F5;
}

/* Responsive */

@media (max-width: 575px) {
  .single-blog .content-670 blockquote {
    max-width: 100%;
    width: 100%;
    float: none;
    margin-left: 0;
  }
}
