* {
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* *::selection {
  background: #fff;
} */

body {
  color: #000000;
  line-height: 2;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  min-width: 320px;
  position: relative;
  overflow-x: hidden;
  background: linear-gradient(to right, #F0FDFF 0%, #F0FDFF 60%, white 60%);
  background-position: center;
}
@media (max-width: 1020px) {
  body {
    background: #F0FDFF;
  }
}

p {
  margin-bottom: 40px;
}

a {
  text-decoration: none;
  color: #000;
  transition: 0.35s ease-out;
}

a:hover {
  color: #0810F5;
}

.section a {
  font-weight: 700;
  color: #0810F5;
}

.section a:hover {
  color: #000;
}

.doc-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #fff;
}

.block-right {
  margin-right: -100px;
}

.block-right .block-right {
  margin-right: 0;
}

img.block-right {
  max-width: calc(100% + 100px);
}

.content-670 {
  max-width: 670px;
}

.relative {
  position: relative;
}

.page-wrapper {
  pointer-events: auto;
  width: 100%;
  background-color: #F0FDFF;
  max-width: 800px;
}

.section h2.title {
  font-weight: 500;
  font-size: 26px;
  line-height: 150%;
  letter-spacing: 5px;
  margin-bottom: 40px;
}

.section {
  margin-top: 65px;
  padding-top: 85px;
}

.single .section {
  min-height: 100vh;
}

.page-wrapper > section:first-of-type {
  margin-top: 0;
}

.page-wrapper > section:last-of-type {
  padding-bottom: 85px;
}
.section .section-des {
  margin-bottom: 40px;
}
