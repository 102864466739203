.s-nav {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  max-width: 40%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  padding: 85px 100px 85px 200px;
}

.nav-count {
  position: relative;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 154px;
}

.nav-count > div {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.nav-count .current-num {
  width: 18px;
}

.nav-count .current-num span {
  left: 0;
  top: 0;
  position: absolute;
  color: #0810F5;
}

.nav-count .pagination-sep {
  padding: 0 12px;
}

.nav-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav-list li a {
  display: inline-block;
}

.nav-list li a.current {
  color: #0810F5;
}

.nav-list li ul {
  padding-left: 28px;
  display: none;
  width: 100% !important;
}

.nav-soc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 200px;
  bottom: 79px;
}

.nav-soc li {
  margin-right: 25px;
}

.nav-soc li:last-child {
  margin-right: 0;
}

.nav-soc li a {
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 5px;
}

.nav-container .site-title {
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 5px;
  font-weight: 700;
  margin-bottom: 51px;
  position: relative;
  display: inline-block;
}

.nav-container .site-title:before {
  content: '';
  position: absolute;
  bottom: -3px;
  right: 5px;
  height: 2px;
  width: calc(100% - 5px);
  transition: 0.35s ease-out;
  background-color: #000000;
}

.nav-container .site-title:hover:before {
  width: 0;
  background-color: #0810F5;
}

.nav-btn {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  padding: 14px 10px;
  transition: 0.15s ease;
  cursor: pointer;
  z-index: 10;
  background-color: #fff;
}

.nav-btn.scroll-bottom {
  top: -50px;
}

.nav-btn:hover .nav-btn-cover:before {
  margin-left: 9px;
}

.nav-btn:hover .nav-btn-cover .menu-line {
  margin-left: 17px;
}

.nav-btn.active .nav-btn-cover .menu-line {
  opacity: 0;
}

.nav-btn.active .nav-btn-cover:before {
  transform: rotate3d(0, 0, 1, 45deg) translate3d(0, 0, 0);
  margin-left: 0;
  width: 26px;
}

.nav-btn.active .nav-btn-cover:after {
  transform: rotate3d(0, 0, 1, -45deg);
  width: 26px;
}

.nav-btn .nav-btn-cover {
  position: relative;
  height: 20px;
  width: 30px;
  display: block;
}

.nav-btn .nav-btn-cover .menu-line {
  width: 13px;
  height: 3px;
  position: absolute;
  transition: all 0.25s;
  margin-left: 0;
  top: 8px;
  background-color: #000;
}

.nav-btn .nav-btn-cover:after,
.nav-btn .nav-btn-cover:before {
  content: '';
  height: 3px;
  width: 30px;
  position: absolute;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.25s;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  top: 8px;
  background-color: #000;
}

.nav-btn .nav-btn-cover:before {
  transform: translate3d(0, -8px, 0px);
  width: 21px;
  margin-left: 0;
}

.nav-btn .nav-btn-cover:after {
  transform: translate3d(0, 8px, 0);
}

/* Responsive */
@media (min-width: 1200px) {
  .nav-btn {
    display: none;
  }
}

/*  */

@media (max-width: 1366px) {
  .s-nav {
    padding: 60px 50px 60px 300px;
  }

  .nav-soc {
    bottom: 50px;
  }

  .nav-count {
    margin-bottom: 130px;
  }
}

@media (min-width: 1200px) and (max-height: 660px) {
  .nav-count {
    margin-bottom: 80px;
  }
}

@media (max-width: 1199px) {
  .s-nav {
    right: auto;
    left: -100%;
    pointer-events: none;
    background-color: #fff;
    max-width: 300px;
    width: 100%;
    z-index: 9;
    padding: 80px 30px 60px;
    overflow: auto;
    height: 100%;
    transition: 0.25s ease-in;
  }

  .s-nav.active {
    transition: 0.25s ease-out;
    box-shadow: 0 0 4px rgba(255, 240, 240, 0.5);
    left: 0;
    pointer-events: auto;
  }

  .dropdown {
    cursor: pointer;
  }

  .dropdown > a {
    pointer-events: none;
  }

  .nav-count {
    margin-bottom: 50px;
  }

  .nav-soc {
    left: 15px;
    bottom: 15px;
  }

  .nav-container {
    padding-bottom: 60px;
  }
}

@media (max-width: 1199px) and (max-height: 550px) {
  .nav-soc {
    position: relative;
    left: auto;
    bottom: auto;
  }

  .nav-container {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .nav-btn {
    background-color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .s-nav {
    z-index: 2;
    padding: 40px 15px 60px;
    margin-top: 46px;
  }
  .nav-count {
    position: fixed;
    top: 12px;
    right: 15px;
    display: block;
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .s-nav {
    max-width: 100%;
  }
}
